import React from 'react';

const Dropdown = ({ options,placeHolder }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };
  const inputStyle = {
    backgroundColor: '#800080',
  };


  return (
    <div className="dropdown">
      <button style={inputStyle}
         className="dropdown-toggle " onClick={toggleDropdown}>
        <span className="text-white focus:ring-purple-500 border-yellow-500 focus:border-yellow-500 ">{placeHolder}</span>
      </button>
      {isOpen && (
        <ul className="dropdown-menu border border-red-500">
          {options.map((option, index) => (
            <li key={index} onClick={() => console.log(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;