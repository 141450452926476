import React from 'react';
import './App.css';
import logo from './logo.png';
import supabase from './supabase';

function Login() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [btn, setBtn] = React.useState('Login');

  const handleLogin = async () => {
    setBtn('Loading...');
    const { data, error } = await supabase.from('users').select().match({ email, password });
    if (error) {
      alert(error.message);
      setBtn('Login');
    } else {
      sessionStorage.setItem('uid', Math.random().toString(36).substring(2));
      window.location.href = '/';
    }
  };

  
  return (
    <div className='flex flex-col items-center justify-between min-h-screen bg-parentPurple py-12'>
      <img src={logo} alt='logo' className='w-24' />
      <div className='flex flex-col items-center space-y-4 w-11/12 md:w-4/6 lg:w-2/6'>
        <h1 className='font-bold text-3xl tracking-wide text-myYellow mb-6'>
          Login
        </h1>
        <div className='flex flex-col w-full items-center space-y-4'>
          <input
            type='text'
            placeholder='Email'
            className='w-full p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-myYellow/50 transition-all ease-in resize-none bg-myPurple/20 border border-myYellow text-myYellow placeholder:text-myYellow/60'
          />
          <input
            type='password'
            placeholder='Password'
            className='w-full p-4 rounded-md focus:outline-none focus:ring-2 focus:ring-myYellow/50 transition-all ease-in resize-none bg-myPurple/20 border border-myYellow text-myYellow placeholder:text-myYellow/60'
          />
          <button 
            onClick={handleLogin}
          className='w-full bg-myYellow text-parentPurple font-bold rounded-md py-4 text-lg'>
            {btn}
          </button>
        </div>
        <a href='/signup' className='text-myYellow'>
          Don't have an account? Signup
        </a>
      </div>
      <div></div>
    </div>
  );
}

export default Login;
