
import './App.css';
import './input.css'
import './output.css'
import Instagram from './Instagram.js';
import Facebook from './Facebook.js';
import Infographics from './Infographics'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import {Switch} from 'react-router-dom'
import Twitter from './Twitter';
import Tiktok from './Tiktok';
import PressRelease from './PressRelease.js';
import RadioAd from './RadioAd.js';
import Youtube from './Youtube';
import BlogPost from './BlogPost';
import Email from './Email';
import Sidebar from './Sidebar';
import Linkedin from './Linkedin';
import Signup from './Signup';
import Login from './Login';

function App() {
  return (
    <>
    <Router>
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route exact path="/">
            <Sidebar abc={<Youtube />} t={true} title="Youtube" />
          </Route>
          <Route exact path='/linkedin'>
            <Sidebar abc={<Linkedin/>} t={true} title="LinkedIn"/>
          </Route>
          <Route exact path='/pressrelease'>
            <Sidebar abc={<PressRelease />}  t={true} title="Press Release"/>
          </Route>
          <Route exact path='/facebook'>
            <Sidebar abc={<Facebook />}  t={true} title="Facebook"/>
          </Route>
          <Route exact path='/instagram'>
            <Sidebar abc={<Instagram />} t={true} title="Instagram"/>
          </Route>
          <Route exact path='/tiktok'>
            <Sidebar abc={<Tiktok />} t={true} title="Tiktok" />
          </Route>
          <Route exact path='/twitter'>
            <Sidebar abc={<Twitter />} t={true} title="Twitter"/>
          </Route>
          <Route path='/email'>
            <Sidebar abc={<Email />} t={true}  title="Email"/>
          </Route>
          <Route exact path='/infographics'>
            <Sidebar abc={<Infographics/>} t={true} title="Infographics"/>
          </Route>
          <Route exact path='/radioad'>
            <Sidebar abc={<RadioAd/>} title="Radio Ads"/>
          </Route>
          <Route exact path='/blogpost'>
            <Sidebar abc={<BlogPost />} title="Blog Post" />
          </Route>
        </Switch>
      </Router>
    
    </>
  );
}

export default App;

